<template>
  <div class="background">
    <b-container fluid>
      <b-row>
        <b-col md="6" class="d-flex justify-content-center">
          <img src="@/assets/ichLogo.png" class="ichLogo">
        </b-col>
        <b-col md="6" class="text-center">
          <h4>สมัครสมาชิก</h4>
          <b-col md="6" offset-md="3" class="formLogin" v-if="!successRegister">
            <b-form-group label="อีเมล:" label-for="email" :horizontal="true">
              <b-form-input id="email" v-model="email" placeholder="อีเมล" required>
              </b-form-input>
            </b-form-group>
            <b-form-group label="ชื่อ - นามสกุล:" label-for="name" :horizontal="true">
              <b-form-input id="name" v-model="name" placeholder="ชื่อ - นามสกุล" required>
              </b-form-input>
            </b-form-group>
            <b-form-group label="รหัสผ่าน:" label-for="password" :horizontal="true">
              <b-form-input id="password" type="password" v-model="password"
              placeholder="รหัสผ่าน" required>
              </b-form-input>
            </b-form-group>
            <b-form-group label="ยืนยันรหัสผ่าน:" label-for="password2" :horizontal="true">
              <b-form-input id="password2" type="password" v-model="password2"
              placeholder="ยืนยันรหัสผ่าน" required>
              </b-form-input>
            </b-form-group>
            <p v-if="errRegister" class="errText">{{registerFail}}</p>
            <p v-if="successRegister" class="successText">{{successRegisterText}}</p>
          </b-col>
          <b-button class="registerBtn" @click="registerUser()" v-if="!successRegister">
            สมัครสมาชิก
          </b-button>
          <b-button class="registerBtn" @click="goToLogin()" v-if="successRegister">
            เข้าสู่ระบบ
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import userAPI from '@/api/user';

export default {
  data() {
    return {
      email: '',
      name: '',
      password: '',
      password2: '',
      errRegister: false,
      registerFail: 'register fail',
      successRegister: false,
      successRegisterText: 'สมัครสมาชิกสำเร็จ',
    };
  },
  methods: {
    async registerUser() {
      if (this.email === '' || this.name === '' || this.password === '' || this.password2 === '') {
        this.registerFail = 'กรุณากรอกข้อมูลให้ครบถ้วน';
        this.errRegister = true;
        return;
      }
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)) {
        if (this.password === this.password2) {
          const register = await userAPI.registerAccount(this.email, this.name, this.password);
          console.log('register', register);
          if (register !== undefined) {
            this.successRegister = true;
            this.errRegister = false;
          } else {
            this.registerFail = 'อีเมลนี้ถูกใช้ไปแล้ว';
            this.errRegister = true;
          }
        } else {
          this.registerFail = 'รหัสผ่านไม่เหมือนกันกรุณากรอกใหม่';
          this.errRegister = true;
        }
      } else {
        this.registerFail = 'กรุณากรอกอีเมลให้ถูกต้อง';
        this.errRegister = true;
      }
    },
    goToLogin() {
      this.$router.push('/backoffice/login');
    },
  },
};
</script>

<style lang="css" scoped>
  .row {
    height: 100vh;
  }
  .col-md-6 {
    margin: auto;
  }
  .ichLogo {
    width: 500px;
  }
  .formLogin {
    text-align: left;
  }
  .background {
    background-color: #FAFAFC;
    border-bottom-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  .errText {
    color: red;
  }
  .successText {
    color: #28a745;
  }
  .registerBtn {
    background: transparent linear-gradient(105deg,#002dff,#ff2aa1) 0 0 no-repeat padding-box;
    border-radius: 15px;
  }
  @media screen and (max-width: 800px) {
    .ichLogo {
      width: 350px;
    }
  }
</style>
